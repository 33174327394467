import React from "react"
import PropTypes from "prop-types"
import Layout from "../../../components/Layout"
import DocumentsVerification from "../../../components/DocumentsVerification"
import Breadcrumb from "../../../components/Breadcrumb"
import Hero from "../../../components/Hero"

const VerificationPageWithSlug = ({ params = {}, pageContext = {}, slug: slugFromProps }) => {
  const slug = pageContext.slug || params.slug || slugFromProps;
  const path = [
    { label: "Inicio", to: "/" },
    {
      label: "Verificación de documentos",
    },
  ]
  return (
    <Layout>
      <Hero image="/imgs/certificaciones-hero.jpg" text="Verificación de documentos" />
      <div className="container950">
        <Breadcrumb path={path} />
        <DocumentsVerification docCode={slug} />
      </div>
    </Layout>
  )
}

VerificationPageWithSlug.propTypes = {
  params: PropTypes.shape({ slug: PropTypes.string }),
}

export default VerificationPageWithSlug
