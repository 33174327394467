import React from "react"
import { Router } from "@reach/router"
import VerificationPage from "."
import VerificationPageWithSlug from "./[slug]"

const App = () => {
  return (
    <Router basepath="/verificacion">
      <VerificationPageWithSlug path="/:slug" />
      <VerificationPage path="/" />
    </Router>
  )
}
export default App
